import { ADMIN_LOGIN, PASSCODE_LOGIN, LOG_OUT } from '../global/constant/apiRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../global/appState/store';
import { useState } from 'react';
import { setAsyncStorage, getAsyncStorage, removeItemAsyncStorage } from '../global/utils/asyncFun';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import { useNavigation } from '@react-navigation/native';
import useCategory from './useCategory';
import useProduct from './useProduct';
import { toast } from 'react-toastify';

const useAuth = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const { apiCall } = useAxios();
  const { setDialogShowState, onSetPreloadState } = useDialogState();
  const navigation: any = useNavigation();
  const { syncCategoryOnLocalDB } = useCategory();
  const { syncProductNameGroupOnLocalDB, syncAllShopProductOnLocalBD } = useProduct();

  const adminLoginFunction = async (data: { password: string; email: string }) => {
    onSetPreloadState(true);
    if (data.email == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Login Error', 'email is needed', 'Try Again');
      return;
    }
    if (data.password == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Login Error', 'password is needed', 'Try Again');
      return;
    }
    try {
      const res = await apiCall('POST', ADMIN_LOGIN, data);
      setAsyncStorage('token', res?.data?.token);
      setAsyncStorage('profile', res?.data?.user);
      setAsyncStorage('org', res?.data?.organisations);
      if (res?.data?.organisations?.shops.length == 1) {
        setAsyncStorage('shop', res?.data?.organisations.shops[0]);
        navigation.replace('app', { screen: 'shopScreen' });
      }

      if (res.data?.organisations?.shops.length > 1) {
        navigation.replace('app', { screen: 'shopScreen' });
      } else {
        setAsyncStorage('shop', res?.data?.organisations?.shops[0]);
        navigation.replace('app', { screen: 'lobbyScreen' });
      }

      onSetPreloadState(false);
    } catch (error: any) {
      onSetPreloadState(false);
      console.log('admin login error =>', error);
      if (error?.code == 'ERR_NETWORK') {
        setDialogShowState(true, 'Network Error', 'Please check your network connectivity', 'Try Again', 'info');
      } else {
        setDialogShowState(true, 'Login Error', error?.response?.data?.message, 'Try Again', 'error');
      }
    }
  };

  const tillLoginFunction = async (data: { passcode: string }) => {
    onSetPreloadState(true);
    if (data.passcode == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Login Error', 'passcode is needed', 'Try Again', 'error');
      return;
    }
    try {
      const res = await apiCall('POST', PASSCODE_LOGIN, data);
      setAsyncStorage('token', res?.data?.token);
      setAsyncStorage('profile', res?.data?.user);
      setAsyncStorage('shop', res?.data?.organisation?.shop);
      setAsyncStorage('org', res?.data?.organisation);
      navigation.replace('app', { screen: 'cartScreen' });
      onSetPreloadState(false);
      syncCategoryOnLocalDB();
      syncProductNameGroupOnLocalDB();
      syncAllShopProductOnLocalBD();
    } catch (error: any) {
      onSetPreloadState(false);
      console.log('admin login error =>', error.response.data);
      if (error?.code == 'ERR_NETWORK') {
        setDialogShowState(true, 'Network Error', 'Please check your network connectivity', 'Try Again', 'info');
      } else {
        setDialogShowState(true, 'Login Error', error?.response?.data?.message, 'Try Again', 'error');
      }
    }
  };

  const logOut = async () => {
    onSetPreloadState(true);
    try {
      onSetPreloadState(false);
      const res = await apiCall('GET', LOG_OUT);
      removeItemAsyncStorage('token');
      removeItemAsyncStorage('profile');
      removeItemAsyncStorage('shop');
      removeItemAsyncStorage('org');
      removeItemAsyncStorage('refundRealData');
      removeItemAsyncStorage('cart');
      removeItemAsyncStorage('cartCount');
      window.electron.ipcRenderer.invoke('clear-category-table').then((res: any) => {
        console.log('clear-category-table==>', res);
      });
      window.electron.ipcRenderer.invoke('clear-productGroupName-table').then((res: any) => {
        console.log('clear-productGroupName-table==>', res);
      });
      window.electron.ipcRenderer.invoke('clear-product-table').then((res: any) => {
        console.log('clear-product-table==>', res);
      });
      navigation.replace('auth', { screen: 'loginOptionScreen' });
      console.log('log out res', res.data);
    } catch (error) {
      onSetPreloadState(false);
      console.log('log out error');
    }
  };

  const activateSoftwareFunction = async (value: string) => {
    try {
      setAsyncStorage('igodo', value);
      toast.success('Software Activated!', {
        position: 'top-right',
      });
    } catch (error) {
      console.log('activateSoftware=>', error);
    }
  };

  const getActivateSoftwareFunction = async () => {
    try {
      const key = await getAsyncStorage('igodo');
      return key;
    } catch (error) {
      console.log('activateSoftware=>', error);
    }
  };

  return {
    adminLoginFunction,
    tillLoginFunction,
    activateSoftwareFunction,
    getActivateSoftwareFunction,
    logOut,
    authLoading,
  };
};

export default useAuth;

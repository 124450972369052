import { View} from 'react-native';
import CustomRoundedButton from './CustomRoundedButton';
import { CancelKey, DotKey } from './svg';
import { FC } from 'react';

interface IProps {
  setFun: (val: string) => void;
}

const CustomNumberKeyboard: FC<IProps> = ({ setFun }) => {
  return (
    <>
      <View style={{ marginTop: 50 }} />
      <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <CustomRoundedButton value={1} onPress={() => setFun('1')} />
        <CustomRoundedButton value={2} onPress={() => setFun('2')} />
        <CustomRoundedButton value={3} onPress={() => setFun('3')} />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 30 }}>
        <CustomRoundedButton value={4} onPress={() => setFun('4')} />
        <CustomRoundedButton value={5} onPress={() => setFun('5')} />
        <CustomRoundedButton value={6} onPress={() => setFun('6')} />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 30 }}>
        <CustomRoundedButton value={7} onPress={() => setFun('7')} />
        <CustomRoundedButton value={8} onPress={() => setFun('8')} />
        <CustomRoundedButton value={9} onPress={() => setFun('9')} />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 30 }}>
        <CustomRoundedButton isComponent={true} value={<DotKey />} onPress={() => setFun('.')} />
        <CustomRoundedButton value={0} onPress={() => setFun('0')} />
        <CustomRoundedButton isComponent={true} value={<CancelKey />} onPress={() => setFun('-')} />
      </View>
     
    </>
  );
};

export default CustomNumberKeyboard;

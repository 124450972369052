import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import React, { FC, useState } from 'react';
import { AddProductByScannerScreenProps } from 'navigation/appNavigation';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import BarcodeReader from 'react-barcode-reader';
import { appColors } from '../../global/constant/colors';
import { Card } from 'react-native-paper';
import { ReactBarcode } from 'react-jsbarcode';
import { CustomInput, CustomButton, CustomImageUpload, CustomDialog } from '../../components';
import useProduct from '../../hooks/useProduct';

const AddProductByScannerScreen: FC<AddProductByScannerScreenProps> = ({ navigation, route }) => {
  const { productNameGroupParamsData }: any = route.params;
  const { createProductItemByScanner } = useProduct();

  const [productData, setProductData] = useState({
    name: productNameGroupParamsData.product_name_group,
    product_name_group_id: productNameGroupParamsData?.id,
    category_id: productNameGroupParamsData.product_category_id,
    description: '',
    is_single: 1,
    stock: '',
    barcode: [],
    cost_per_unit: '',
  });

  const handleScan = (result: any) => {
    if (result && productData.barcode.length < 1) {
      setProductData((prevData) => ({
        ...prevData,
        barcode: [...prevData.barcode, result],
      }));
      console.log('scan result', result);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };
  const onClearFun = () => {
    setProductData({
      ...productData,
      name: productNameGroupParamsData.product_name_group,
      description: '',
      barcode: [],
      stock: '',
      cost_per_unit: '',
    });
  };
  const onSubmit = () => {
    createProductItemByScanner(
      {
        name: productData.name,
        product_name_group_id: productData.product_name_group_id,
        category_id: productData.category_id,
        description: productData.description,
        is_single: 1,
        stock: Number(productData.stock),
        barcode: productData.barcode,
        cost_per_unit: productData.cost_per_unit,
      },
      onClearFun,
    );
  };

  return (
    <>
      <AdminLayout
        content={
          <div style={{ height: '100%' }}>
            <View>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <View style={{ flexDirection: 'row' }}>
                  <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                    <Path
                      d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                      fill="#1E1E1E"
                    />
                  </Svg>
                  <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>ADD PRODUCT</Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{ marginTop: 40 }}>
              <FlatList
                ListHeaderComponentStyle={{ width: '100%' }}
                ListHeaderComponent={
                  <>
                    <View style={{ flexDirection: 'row', marginTop: -20 }}>
                      <View style={{ width: '15%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                        <CustomInput
                          value={productData.name}
                          placeholder="Product Name"
                          onChangeText={(e) => setProductData({ ...productData, name: e })}
                          inputMode="text"
                          editable={false}
                        />
                      </View>
                      <View style={{ width: '15%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                        <CustomInput
                          value={productNameGroupParamsData.price}
                          placeholder="Product Price"
                          onChangeText={(e) => {}}
                          inputMode="text"
                          editable={false}
                        />
                      </View>
                      <View style={{ width: '15%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                        <CustomInput
                          value={productData.stock}
                          placeholder="Stock"
                          onChangeText={(e) => setProductData({ ...productData, stock: e })}
                          inputMode="text"
                        />
                      </View>
                      <View style={{ width: '15%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                        <CustomInput
                          value={productData.cost_per_unit}
                          placeholder="Cost Price Per Unit"
                          onChangeText={(e) => setProductData({ ...productData, cost_per_unit: e })}
                          inputMode="text"
                        />
                      </View>
                      <View style={{ width: '15%', height: '100%', marginBottom: 50, marginRight: 20 }}>
                        <CustomInput
                          value={productData.description}
                          placeholder="Product Description"
                          onChangeText={(e) => setProductData({ ...productData, description: e })}
                          inputMode="text"
                        />
                      </View>
                      {productData?.barcode.length >= 1 && productData?.stock.length >= 1 && (
                        <View style={{ width: '10%', height: '100%', marginBottom: 50, marginTop: 30 }}>
                          <CustomButton
                            fontSize={14}
                            padding={5}
                            width={'100%'}
                            bntType="primary"
                            mode="contained"
                            text="SAVE"
                            onPress={onSubmit}
                          />
                        </View>
                      )}
                    </View>
                    <BarcodeReader onError={handleError} onScan={handleScan} />
                  </>
                }
                contentContainerStyle={{
                  justifyContent: 'space-between',
                  margin: 10,
                }}
                keyExtractor={(item: any) => item}
                numColumns={4}
                ListFooterComponent={
                  <>
                    {productData.barcode.length > 0 && (
                      <TouchableOpacity
                        onPress={() => setProductData({ ...productData, barcode: [] })}
                        style={{
                          backgroundColor: 'rgba(183, 35, 0, 1)',
                          padding: 15,
                          borderRadius: 10,
                          width: 320,
                          marginRight: 10,
                        }}
                      >
                        <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700' }}>Remove</Text>
                      </TouchableOpacity>
                    )}
                  </>
                }
                renderItem={({ item }) => (
                  <Card
                    style={{
                      backgroundColor: '#ffffff',
                      height: 150,
                      width: 320,
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                    mode="elevated"
                  >
                    <Card.Content>
                      <View style={{ alignItems: 'center' }}>
                        <View style={{ marginLeft: 10 }}>
                          <Text style={{ fontWeight: '600', fontSize: 13, marginTop: 2, color: appColors.primary }}>
                            {productData.name}
                          </Text>
                        </View>
                        <ReactBarcode value={item} options={{ height: 50 }} />
                      </View>
                    </Card.Content>
                  </Card>
                )}
                data={productData.barcode}
              />
            </View>
            <CustomDialog />
          </div>
        }
      />
    </>
  );
};

export default AddProductByScannerScreen;

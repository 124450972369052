import { View, Text, Image, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Appbar, Menu, Divider} from 'react-native-paper';
import { appColors } from '../global/constant/colors';
import { useNavigation } from '@react-navigation/native';
import useOrg from '../hooks/useOrg';
import useAuth from '../hooks/useAuth';
import useUsers from '../hooks/useUsers';
import useProduct from '../hooks/useProduct';
import useCategory from '../hooks/useCategory';
import CustomButton from './CustomButton';

const AdminHeader = () => {
  const {getAllShopProduct, } =useProduct()
  const {getAllCategory} = useCategory()
  const { getUserFromAsync} = useUsers();
  const { org, getOrgData, onChangeShop } = useOrg();
  const { logOut } = useAuth();
  const navigation: any = useNavigation();

  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  useEffect(() => {
    getOrgData();
  }, []);

  const switchShop = (data: any) => {
    onChangeShop(data);
    closeMenu();
  };

  const [user, setUser] = useState<any>({});

  useEffect(() => {
    getUserFromAsync().then((data: any) => {
      setUser(data);
    });
  }, []);
  const goToTill = () => {
    getAllCategory()
    getAllShopProduct()
    navigation.replace('app', { screen: 'cartScreen' })
  }

  return (
    <Appbar.Header style={{ backgroundColor: appColors.primary }}>
      <Appbar.Content color="#ffffff" title={`${user.first_name} (${user.role})`} />
      <View style={{ marginRight: 60 }}>
      <View style={{ marginLeft: 20 }}>
          <CustomButton
            bntType="secondary"
            mode="outlined"
            text="Back To Till"
            onPress={() => goToTill()}
          />
        </View>
        
      </View>
      <View style={{ marginRight: 60 }}>
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <TouchableOpacity
              onPress={openMenu}
              style={{
                backgroundColor: '#ffffff',
                height: 40,
                width: 40,
                borderRadius: 10,
                justifyContent: 'center',
                alignContent: 'center',
                shadowColor: '#ffffff',
              }}
            >
              <Image style={{ height: 40, width: 40 }} source={require('../assets/menu.png')} />
            </TouchableOpacity>
          }
        >
          {org?.shops?.map((data: any) => (
            <Menu.Item key={data.id} onPress={() => switchShop(data)} title={data?.shop_name} />
          ))}
          <Divider />
          <Menu.Item titleStyle={{ color: 'red', fontWeight: 'bold' }} onPress={() => logOut()} title="LOG OUT" />
        </Menu>
      </View>
    </Appbar.Header>
  );
};

export default AdminHeader;

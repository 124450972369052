/**
 * @format
 */

import { AppRegistry } from 'react-native-web';
import App from './App';
// import { name as appName } from './app.json';
const appName = "till-seller"

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
    initialProps: {},
    rootTag: document.getElementById('root'),
});

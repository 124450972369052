import React from 'react';
import { SafeAreaView } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import store from './global/appState/store';
import AppNavigator from './navigation';
import CustomSpinner from './components/CustomSpinner';
import useSpinner from './hooks/useSpinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App(): React.JSX.Element {
  const { spinnerState } = useSpinner();

  return (
    <>
      <Provider store={store}>
        <PaperProvider>
          <SafeAreaView style={{ flex: 1, backgroundColor: '#efeded' }}>
            <AppNavigator />
            <CustomSpinner />
            <ToastContainer />
          </SafeAreaView>
        </PaperProvider>
      </Provider>
    </>
  );
}

export default App;

import { CREATE_USER, GET_USER, CHANGE_USER_STATUS, EDIT_USER, GET_USER_LOG } from '../global/constant/apiRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../global/appState/store';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import { setUserData, setUserLogData, setUserLogDataLoading } from '../global/appState/slice/customUserSlice';
import { getAsyncStorage } from '../global/utils/asyncFun';
import { toast } from 'react-toastify';
import moment from 'moment';

const useUsers = () => {
  const { apiCall } = useAxios();
  const dispatch = useDispatch();
  const { setDialogShowState, onSetPreloadState } = useDialogState();
  const { userData, userLogData, loading }: any = useSelector((state: RootState) => state.customUser);

  const getUser = async () => {
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_USER}?shop_id=${shopId.id}`);
      console.log('user dat', res.data.users);
      dispatch(setUserData(res.data.users));
    } catch (error) {
      console.log('get user error', error);
    }
  };

  const getUserFromAsync = async () => {
    try {
      let user = await getAsyncStorage('profile');
      return user;
    } catch (error) {
      console.log('get user error', error);
    }
  };

  const getUserLog = async (userId: number, page?: number) => {
    setUserLogDataLoading(true);
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall(
        'GET',
        page == undefined
          ? `${GET_USER_LOG}?user_id=${userId}&shop_id=${shopId.id}`
          : `${GET_USER_LOG}?user_id=${userId}&shop_id=${shopId.id}&page=${page}`,
      );
      console.log('user log =>', res.data.data);
      dispatch(setUserLogData(res.data.data));
      setUserLogDataLoading(false);
      onSetPreloadState(false);
    } catch (error) {
      console.log('get user error', error);
      setUserLogDataLoading(false);
      onSetPreloadState(false);
    }
  };

  const getUserLogByDate = async (
    userId: number,
    _data?: { date_from: string; date_to: string } | any,
    page?: number,
  ) => {
    setUserLogDataLoading(true);
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall(
        'GET',
        page == 0
          ? `${GET_USER_LOG}?user_id=${userId}&shop_id=${shopId.id}&date_from=${moment(_data.date_from).format('YYYY-MM-DD')}&date_to=${moment(_data.date_to).format('YYYY-MM-DD')}`
          : `${GET_USER_LOG}?user_id=${userId}&shop_id=${shopId.id}&date_from=${moment(_data.date_from).format('YYYY-MM-DD')}&date_to=${moment(_data.date_to).format('YYYY-MM-DD')}&page=${page}`,
      );
      console.log('user log =>', res.data.data);
      onSetPreloadState(false);
      dispatch(setUserLogData(res.data.data));
      setUserLogDataLoading(false);
    } catch (error) {
      console.log('get user error', error);
      setUserLogDataLoading(false);
      onSetPreloadState(false);
    }
  };

  const createUser = async (
    data: {
      first_name: string;
      surname: string;
      email: string;
      password: string;
      password_confirmation: string;
      roles: number;
      phone: string;
    },
    nextFun?: any,
  ) => {
    console.log('date for create user', data);
    onSetPreloadState(true);
    if (data.first_name == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'first name is needed', 'Try Again', 'error');
      return;
    }
    if (data.surname == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'surname is needed', 'Try Again', 'error');
      return;
    }
    if (data.email == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'email is needed', 'Try Again', 'error');
      return;
    }
    if (data.phone == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'password dose not match', 'Try Again', 'error');
      return;
    }

    if (data.password == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'password is needed', 'Try Again', 'error');
      return;
    }
    if (data.password != data.password_confirmation) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'role is needed', 'Try Again', 'error');
      return;
    }

    if (data.roles == 0) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'password dose not match', 'Try Again', 'error');
      return;
    }

    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('POST', CREATE_USER, {
        first_name: data.first_name,
        surname: data.surname,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        roles: [data.roles],
        phone: data.phone,
        shop_id: shopId.id,
      });
      console.log(res?.data);
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Success', res.data.message, 'Continue', 'success');
      nextFun();
      getUser();
    } catch (error: any) {
      console.log('Create User Error =>', error);
      onSetPreloadState(false);
      if (error.code == 'ERR_NETWORK') {
        onSetPreloadState(false);
        setDialogShowState(true, 'Network Error', 'Please check your network connectivity', 'Try Again', 'info');
      } else {
        onSetPreloadState(false);
        setDialogShowState(true, 'Create User Error', error.response.data.message, 'Try Again', 'error');
      }
    }
  };

  const editUser = async (
    data: {
      user_id: string;
      first_name: string;
      surname: string;
      passcode: string;
      phone: string;
    },
    nextFun?: any,
  ) => {
    onSetPreloadState(true);
    if (data.first_name == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'first name is needed', 'Try Again', 'error');
      return;
    }
    if (data.surname == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'surname is needed', 'Try Again', 'error');
      return;
    }
    if (data.phone == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'password dose not match', 'Try Again', 'error');
      return;
    }

    if (data.passcode == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create User Error', 'passcode is needed', 'Try Again', 'error');
      return;
    }

    try {
      const res = await apiCall('POST', EDIT_USER, data);
      console.log(res.data);
      onSetPreloadState(false);
      toast.success(`${res.data.message}`, {
        position: 'top-right',
      });
      nextFun();
      getUser();
    } catch (error: any) {
      console.log('Create User Error =>', error.response.data);
      onSetPreloadState(false);
      if (error.code == 'ERR_NETWORK') {
        setDialogShowState(true, 'Network Error', 'Please check your network connectivity', 'Try Again', 'info');
      } else {
        setDialogShowState(true, 'Update User Error', error.response.data.message, 'Try Again', 'error');
      }
    }
  };

  const setUserState = async (data: any) => {
    onSetPreloadState(true);
    try {
      const res = await apiCall('POST', CHANGE_USER_STATUS, data);
      if (res) {
        toast.success(`Uer is now ${data.is_active == 0 ? 'Deactivated' : 'Active'}`, {
          position: 'top-right',
        });
        onSetPreloadState(false);
        getUser();
      }
    } catch (error) {
      onSetPreloadState(false);
      console.log('Change  User State Error =>', error.response.data);
      toast.success(`${error.response.data}`, {
        position: 'top-right',
      });
    }
  };

  return {
    createUser,
    getUser,
    setUserState,
    editUser,
    getUserLog,
    getUserLogByDate,
    getUserFromAsync,
    userData,
    userLogData,
    userLogLoading: loading,
  };
};

export default useUsers;

import { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, TextInput, ScrollView, FlatList } from 'react-native';
import { appColors } from '../global/constant/colors';
import CustomTillProductCard from './CustomTillProductCard';
import { Svg, Path } from 'react-native-svg';
import useCategory from '../hooks/useCategory';
import useProduct from '../hooks/useProduct';
import useOrg from '../hooks/useOrg';
import CustomButton from './CustomButton';

const CustomCategoryCard = () => {
  // const [showProduct, setShowProduct] = useState('category');
  const {
    getAllCategory,
    getAllCategoryOnLocalDB,
    searchCategoryWithKeyWordOnLocalDB,
    categoryData,
    showProduct,
    setShowProduct,
  } = useCategory();
  const {
    getAllProductNameGroup,
    getProductItem,
    getAllProductNameGroupOnLocalDB,
    searchProductGroupNameWithKeyWordOnLocalDB,
    getAllProductOnLocalDB,
    getAllProductOnLocalDBByGroupNameId,
    productNameGroupData,
    productNameItemData,
  } = useProduct();
  const { getOrgData, org } = useOrg();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getAllCategoryOnLocalDB();
    getOrgData();
  }, []);

  useEffect(() => {
    searchCategoryWithKeyWordOnLocalDB(searchQuery);
    searchProductGroupNameWithKeyWordOnLocalDB(searchQuery);
    getOrgData();
  }, [searchQuery]);

  const onMoveToProductNameScreen = (id: number) => {
    setShowProduct('productName');
    getAllProductNameGroupOnLocalDB(id);
  };

  const onMoveToProductScreen = (id: number) => {
    getAllProductOnLocalDBByGroupNameId(id);
    setShowProduct('product');
  };

  const onHandleBackNavigation = () => {
    if (showProduct == 'product') {
      setShowProduct('productName');
    } else {
      setShowProduct('category');
    }
  };
  const onLoad = () => {
    searchCategoryWithKeyWordOnLocalDB(searchQuery);
    searchProductGroupNameWithKeyWordOnLocalDB(searchQuery);
  };

  return (
    <>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <TouchableOpacity onPress={onHandleBackNavigation}>
          <View style={{ flexDirection: 'row' }}>
            {showProduct !== 'category' && (
              <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                <Path
                  d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                  fill="#1E1E1E"
                />
              </Svg>
            )}

            <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>
              {showProduct == 'product'
                ? 'PRODUCT'
                : showProduct == 'category'
                  ? 'CATEGORY'
                  : showProduct == 'productName'
                    ? 'PRODUCT NAME GROUP'
                    : ''}
            </Text>
          </View>
        </TouchableOpacity>
        {showProduct !== 'product' && (
          <div style={{ marginTop: -20, width: '100%', marginLeft: 20, justifyContent: 'flex-end', display: 'flex' }}>
            <TextInput
              textAlign="center"
              textAlignVertical="center"
              keyboardAppearance="default"
              placeholder="Search"
              style={{
                height: 50,
                padding: 13,
                backgroundColor: appColors.secondary,
                borderColor: 'rgba(30, 30, 30, 0.5)',
                borderWidth: 1,
                borderRadius: 10,
              }}
              value={searchQuery}
              onChangeText={setSearchQuery}
            />
          </div>
        )}
      </View>

      {showProduct == 'category' ? (
        <FlatList
          contentContainerStyle={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
          }}
          ListHeaderComponent={
            <>
              {categoryData.length < 1 && (
                <>
                  <Text style={{ fontSize: 15, fontWeight: '900' }}>No Category Create Yet</Text>
                  <div style={{marginTop:"100px"}}>
                    <CustomButton
                      padding={10}
                      width={'100%'}
                      bntType="primary"
                      mode="outlined"
                      text={'Load Data'}
                      onPress={() => onLoad()}
                    />
                  </div>
                </>
              )}
            </>
          }
          keyExtractor={(item) => item.id}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <TouchableOpacity key={item.id} style={{ margin: 10 }} onPress={() => onMoveToProductNameScreen(item.id)}>
              <View
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  height: 100,
                  width: 200,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 5,
                  marginTop: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 15,
                }}
              >
                <Text
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  style={{
                    color: appColors.primary,
                    fontSize: 13,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  }}
                >
                  {item.name}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          data={categoryData}
        />
      ) : (
        <></>
      )}

      {showProduct == 'productName' ? (
        <FlatList
          contentContainerStyle={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 40,
          }}
          ListHeaderComponent={
            <>
              {productNameGroupData?.length < 1 && (
                <Text style={{ fontSize: 15, fontWeight: '900' }}>No Product Name Group Create Yet</Text>
              )}
            </>
          }
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          numColumns={3}
          renderItem={({ item }) => (
            <TouchableOpacity style={{ margin: 10, borderRadius: 15 }} onPress={() => onMoveToProductScreen(item.id)}>
              <View
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  height: 130,
                  width: 200,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 5,
                  marginTop: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 15,
                }}
              >
                <Text
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  style={{
                    color: appColors.primary,
                    fontSize: 13,
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  }}
                >
                  {item.product_name_group}
                </Text>
                <Text
                  style={{
                    color: appColors.primary,
                    fontSize: 15,
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    marginTop: 10,
                  }}
                >
                  {org?.country?.currency_symbol}
                  {item.price}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          data={productNameGroupData}
        />
      ) : null}

      {showProduct == 'product' ? (
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            marginTop: 40,
          }}
          ListHeaderComponent={
            <>
              {productNameItemData.length < 1 && (
                <Text style={{ fontSize: 15, fontWeight: '900', textAlign: 'center' }}>No Product Created Yet</Text>
              )}
            </>
          }
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => <CustomTillProductCard data={item} />}
          data={productNameItemData}
        />
      ) : null}
    </>
  );
};

export default CustomCategoryCard;

import { View, TouchableOpacity, Text, TextInput } from 'react-native';
import { CustomUserCard, CustomButton, CustomModal } from '../../components';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import { TransactionScreenProps } from '../../navigation/appNavigation';
import { FC, useEffect } from 'react';
import { DataTable } from 'react-native-paper';
import { useState } from 'react';
import useTransaction from '../../hooks/useTransaction';
import { Chip } from 'react-native-paper';
import moment from 'moment';
import useOrg from '../../hooks/useOrg';

const TransactionScreen: FC<TransactionScreenProps> = ({ navigation }) => {
  const { getOrgData, org } = useOrg();
  const {
    getTransactionList,
    getTransactionListByDate,
    getReceipt,
    onClearReceiptData,
    transactionData,
    receiptData,
    totalSum,
    estimated_profit,
  } = useTransaction();
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([20, 30, 50, 100]);
  const [itemsPerPage, onItemsPerPageChange] = useState(numberOfItemsPerPageList[0]);
  const [_date, _setDate] = useState<any>({
    date_from: '',
    date_to: '',
  });

  const [visible, setVisible] = useState(false);
  const showModal = (val: number) => {
    getReceipt(val);
    setVisible(true);
  };

  const hideModal = () => {
    onClearReceiptData();
    setVisible(false);
  };
  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, transactionData?.data?.length);

  useEffect(() => {
    setPage(0);
    getTransactionList();
    getOrgData();
  }, []);

  useEffect(() => {
    getTransactionListByDate(_date);
  }, [_date]);

  // const onChnagePerpage = (val) => {
  //   console.log('==>', val);
  //   onItemsPerPageChange(val);
  //   getUserLog(userId, val);
  // };

  const onChangePage = (val: any) => {
    setPage(val);
    if (_date.date_from.length > 1) {
      getTransactionListByDate(_date, val);
    } else {
      getTransactionList(itemsPerPage, val);
    }
  };

  return (
    <>
      <AdminLayout
        content={
          <View>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <View style={{ flexDirection: 'row' }}>
                    <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                      <Path
                        d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                        fill="#1E1E1E"
                      />
                    </Svg>
                    <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>
                      TRANSACTIONS LOG
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginLeft: 'auto' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ marginRight: 10, marginTop: 20 }}>
                    <TextInput
                      textAlign="center"
                      textAlignVertical="center"
                      keyboardAppearance="default"
                      placeholder="Search"
                      style={{
                        height: 50,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 400,
                        backgroundColor: '#efeded',
                      }}
                      // value={searchQuery}
                      // onChangeText={setSearchQuery}
                    />
                  </View>
                  <View style={{ marginRight: 10 }}>
                    <Text>Date From</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_from: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>
                  <View style={{ marginRight: 10 }}>
                    <Text>Date To</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_to: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>
                  {/* <View style={{ marginTop: 16 }}>
                    <CustomButton
                      fontSize={14}
                      padding={5}
                      width={200}
                      bntType="primary"
                      mode="contained"
                      text="ADD CASHIER"
                      onPress={() => navigation.navigate('createUserScreen')}
                    />
                  </View> */}
                </View>
              </View>
            </View>

            <DataTable style={{ backgroundColor: '#ffffff', borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
              <DataTable.Header>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Cashier</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Amount</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Profit</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Method</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Status</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Transaction Code</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Date</DataTable.Title>
                <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>{''}</DataTable.Title>
              </DataTable.Header>

              {transactionData?.data?.map((item: any) => (
                <DataTable.Row key={item.id}>
                  <DataTable.Cell>{item.cashier_name}</DataTable.Cell>
                  <DataTable.Cell>
                    {org?.country?.currency_symbol} {Number(item.total).toFixed(2)}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {org?.country?.currency_symbol}  {Number(item.order_profit).toFixed(2)}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Chip
                      style={{ backgroundColor: item.payment_method == 0 ? '#e8def8' : '#6750a4' }}
                      textStyle={{ color: item.payment_method == 0 ? '#000000' : '#ffffff' }}
                    >
                      {item.payment_type}
                    </Chip>{' '}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Chip
                      style={{ backgroundColor: item.has_refund == 0 ? 'green' : 'red' }}
                      textStyle={{ color: '#ffffff' }}
                    >
                      {item.has_refund == 0 ? 'no refund' : 'refund'}
                    </Chip>{' '}
                  </DataTable.Cell>
                  <DataTable.Cell>{item.barcode}</DataTable.Cell>
                  <DataTable.Cell>{moment(item.created_at).format('YYYY-MM-DD')}</DataTable.Cell>
                  <DataTable.Cell>
                    <CustomButton
                      fontSize={12}
                      padding={1}
                      width={150}
                      bntType="primary"
                      mode="outlined"
                      text="Order Details"
                      onPress={() => showModal(item.id)}
                    />
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
              <div style={{ display: 'flex' }}>
                <Text style={{ marginLeft: 20, marginTop: 10, fontWeight: 'bold', fontSize: 14 }}>
                  Total Sales: {org?.country?.currency_symbol} {Number(totalSum).toFixed(2)} 
                </Text>
                <Text style={{ marginLeft: 20, marginTop: 10, fontWeight: 'bold', fontSize: 14 }}>
                  Total Profit: {org?.country?.currency_symbol}{' '}
                  {Number(estimated_profit).toFixed(2)}
                </Text>
              </div>
              <DataTable.Pagination
                page={transactionData?.current_page}
                numberOfPages={transactionData?.last_page}
                onPageChange={(page) => onChangePage(page)}
                label={`${from + 1}-${to} of ${transactionData?.total}`}
                numberOfItemsPerPageList={numberOfItemsPerPageList}
                // numberOfItemsPerPage={itemsPerPage}
                // onItemsPerPageChange={(val) => onChnagePerpage(val)}
                selectPageDropdownLabel={'Rows per page'}
              />
            </DataTable>
            <CustomModal
              content={
                <>
                  <div style={{ overflow: 'auto', height: '500px' }}>
                    <DataTable
                      style={{ backgroundColor: '#ffffff', borderRadius: 20, marginTop: 30, marginBottom: 30 }}
                    >
                      <DataTable.Header>
                        <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Product</DataTable.Title>
                        <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Quantity</DataTable.Title>
                        <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Price</DataTable.Title>
                        <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Total</DataTable.Title>
                      </DataTable.Header>

                      {receiptData?.order_products?.map((item: any) => (
                        <DataTable.Row key={item.id}>
                          <DataTable.Cell>{item.name}</DataTable.Cell>
                          <DataTable.Cell>{item.quantity}</DataTable.Cell>
                          <DataTable.Cell>
                            {org?.country?.currency_symbol} {item.price}
                          </DataTable.Cell>
                          <DataTable.Cell>
                            {org?.country?.currency_symbol} {item.sub_total}
                          </DataTable.Cell>
                        </DataTable.Row>
                      ))}
                    </DataTable>
                  </div>
                </>
              }
              marginLeft={450}
              marginRight={450}
              hideModal={hideModal}
              visible={visible}
            />
          </View>
        }
      />
    </>
  );
};

export default TransactionScreen;

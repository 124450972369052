import { CREATE_CATEGORY, UPDATE_CATEGORY, GET_CATEGORY } from '../global/constant/apiRoutes';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCategoryData, setShowProductData } from '../global/appState/slice/customCategorySlice';
import { getAsyncStorage } from '../global/utils/asyncFun';
import { RootState } from '../global/appState/store';

const useCategory = () => {
  const [categoryLoading, setCategoryLoading] = useState(false);
  const dispatch = useDispatch();
  const { apiCall } = useAxios();
  const { setDialogShowState, onSetPreloadState } = useDialogState();
  const { categoryData, showProduct }: any = useSelector((state: RootState) => state.customCategory);

  const getAllCategory = async (search = '') => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall(
        'GET',
        search.length >= 1
          ? `${GET_CATEGORY}?shop_id=${shopId.id}&search=${search}`
          : `${GET_CATEGORY}?shop_id=${shopId.id}`,
      );
      console.log('category', res.data.categories);
      dispatch(setCategoryData(res.data.categories));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get category error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const createCategory = async (data: { name: string; description: string }, nextFun?: any) => {
    onSetPreloadState(true);
    if (data.name == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create category error', 'category name is needed', 'Try Again');
      return;
    }
    if (data.description == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create category error', 'category description  is needed', 'Try Again');
      return;
    }
    if (/\s/.test(data.name)) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create category error', 'category name should not have space', 'Try Again');
      return;
    }
    if (/^[a-zA-Z0-9]+$/.test(data.name) == false) {
      onSetPreloadState(false);
      setDialogShowState(
        true,
        'Create category error',
        'category  name should only contain alphanumeric characters ',
        'Try Again',
      );
      return;
    }

    if (categoryData.some((obj: any) => obj.name?.toLowerCase() == data.name.toLowerCase())) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create category error', 'nice try, but this category already exist', 'Try Again');
      return;
    }

    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('POST', CREATE_CATEGORY, {
        name: data.name,
        description: data.description,
        shop_id: shopId.id,
      });
      onSetPreloadState(false);
      setDialogShowState(true, 'Create Category Success', res.data.message, 'Continue', 'success');
      getAllCategory();
      nextFun();
    } catch (error: any) {
      console.log('Create category error =>', error.response);
    }
  };

  const syncCategoryOnLocalDB = async () => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_CATEGORY}?shop_id=${shopId.id}`);
      const data = res.data.categories.map((val: any) => ({
        name: val.name,
        id: val.id,
      }));

      const result = await window.electron.ipcRenderer.invoke('insert-category', data);
    } catch (error) {
      console.log('get category error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getAllCategoryOnLocalDB = async (search = '') => {
    onSetPreloadState(true);
    try {
      window?.electron?.ipcRenderer
        .invoke('fetch-category')
        .then((res: any) => {
          console.log('category on local DB', res);
          dispatch(setCategoryData(res));
        })
        .catch((error: any) => {
          console.error('Failed to fetch category:  local BD ', error);
        });

      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get category error  local BD  =>', error.response);
      onSetPreloadState(false);
    }
  };

  const searchCategoryWithKeyWordOnLocalDB = async (search = '') => {
    onSetPreloadState(true);
    try {
      const result = await window.electron.ipcRenderer.invoke('search-category', search);

      if (result.success) {
        console.log('Search category local BD  results:', result.result);
        dispatch(setCategoryData(result.result));
      } else {
        console.error('Search failed:', result.error);
      }

      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get category error  local BD  =>', error);
      onSetPreloadState(false);
    }
  };
  
  const setShowProduct = async (data: any) => {
    dispatch(setShowProductData(data));
  };

  return {
    createCategory,
    getAllCategory,
    syncCategoryOnLocalDB,
    getAllCategoryOnLocalDB,
    searchCategoryWithKeyWordOnLocalDB,
    setShowProduct,
    categoryLoading,
    categoryData,
    showProduct,
  };
};

export default useCategory;

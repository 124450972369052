import { createSlice } from '@reduxjs/toolkit';

const customCartSlice = createSlice({
  name: 'cartState',
  initialState: {
    cartData: [],
    cartCount: 0,
    cartCheckOutData: 0,
    refundCheckOutData: {
      products: [],
    },
  },
  reducers: {
    setCartData(state, action) {
      state.cartData = action.payload;
    },
    setCartCount(state, action) {
      state.cartCount = action.payload;
    },
    setCartCheckOutData(state, action) {
      state.cartCheckOutData = action.payload;
    },
    setRefundCheckOutData(state, action) {
      state.refundCheckOutData = action.payload;
    },
  },
});

export const { setCartData, setCartCount, setCartCheckOutData, setRefundCheckOutData } = customCartSlice.actions;

export default customCartSlice;

import { View, TouchableOpacity, Text } from 'react-native';
import { CustomUserCard, CustomButton } from '../../components';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import { UserLogScreenProps } from '../../navigation/appNavigation';
import { FC, useEffect } from 'react';
import { DataTable } from 'react-native-paper';
import { useState } from 'react';
import useUsers from '../../hooks/useUsers';
import { Chip } from 'react-native-paper';
import moment from 'moment';

const UserLogScreen: FC<UserLogScreenProps> = ({ navigation, route }) => {
  const { userId }: any = route.params;
  const { getUserLog, userLogData, userLogLoading, getUserLogByDate } = useUsers();
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([20, 30, 50, 100]);
  const [itemsPerPage, onItemsPerPageChange] = useState(numberOfItemsPerPageList[0]);
  const [_date, _setDate] = useState({
    date_from: '',
    date_to: '',
  });

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, userLogData?.data?.length);

  useEffect(() => {
    setPage(0);
    getUserLog(userId);
  }, []);

  useEffect(() => {
    if (_date.date_from.length > 1 && _date.date_to.length > 1) {
      getUserLogByDate(userId, _date, page);
    }
  }, [_date]);

  // const onChnagePerpage = (val) => {
  //   console.log('==>', val);
  //   onItemsPerPageChange(val);
  //   getUserLog(userId, val);
  // };

  const onChangePage = (val: any) => {
    setPage(val);
    if (_date.date_from.length > 1) {
      getUserLogByDate(userId, _date, val);
    } else {
      getUserLog(userId, val);
    }

    console.log('page=>>', val);
  };

  return (
    <>
      <AdminLayout
        content={
          <View>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <View style={{ flexDirection: 'row' }}>
                    <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                      <Path
                        d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                        fill="#1E1E1E"
                      />
                    </Svg>
                    <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>
                      CASHIER LOGS
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={{ marginLeft: 'auto' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ marginRight: 10 }}>
                    <Text>Date From</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_from: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>
                  <View style={{ marginRight: 10 }}>
                    <Text>Date To</Text>
                    <input
                      onChange={(val: any) => _setDate({ ..._date, date_to: val.target.value })}
                      type="date"
                      style={{
                        height: 20,
                        padding: 13,
                        borderColor: 'rgba(30, 30, 30, 0.5)',
                        borderWidth: 1,
                        borderRadius: 10,
                        width: 200,
                        backgroundColor: '#efeded',
                      }}
                    />
                  </View>
                  {/* <View style={{ marginTop: 16 }}>
                    <CustomButton
                      fontSize={14}
                      padding={5}
                      width={200}
                      bntType="primary"
                      mode="contained"
                      text="ADD CASHIER"
                      onPress={() => navigation.navigate('createUserScreen')}
                    />
                  </View> */}
                </View>
              </View>
            </View>
            {!userLogLoading && (
              <DataTable style={{ backgroundColor: '#ffffff', borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <DataTable.Header>
                  <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Date</DataTable.Title>
                  <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Time</DataTable.Title>
                  <DataTable.Title textStyle={{ fontWeight: '800', color: '#000000' }}>Type</DataTable.Title>
                </DataTable.Header>

                {userLogData?.data?.map((item: any) => (
                  <DataTable.Row key={item.id}>
                    <DataTable.Cell>{moment(item.created_at).format('YYYY-MM-DD')}</DataTable.Cell>
                    <DataTable.Cell>{moment(item.created_at).format('HH:mm:ss')}</DataTable.Cell>
                    <DataTable.Cell>
                      <Chip
                        style={{ backgroundColor: item.type == 0 ? 'green' : 'red' }}
                        textStyle={{ color: '#ffffff' }}
                      >
                        {item.type == 0 ? 'Login' : 'Logout'}
                      </Chip>{' '}
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}

                <DataTable.Pagination
                  page={userLogData?.current_page}
                  numberOfPages={userLogData?.last_page}
                  onPageChange={(page) => onChangePage(page)}
                  label={`${from + 1}-${to} of ${userLogData?.total}`}
                  numberOfItemsPerPageList={numberOfItemsPerPageList}
                  // numberOfItemsPerPage={itemsPerPage}
                  // onItemsPerPageChange={(val) => onChnagePerpage(val)}
                  selectPageDropdownLabel={'Rows per page'}
                />
              </DataTable>
            )}
          </View>
        }
      />
    </>
  );
};

export default UserLogScreen;

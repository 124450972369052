import { View, Text, Image } from 'react-native';
import { Card } from 'react-native-paper';
import CustomButton from './CustomButton';
import { appColors } from '../global/constant/colors';
import useCart from '../hooks/useCart';

interface IProps {
  barcode: string;
  is_single: number;
  price: string;
  product_category_description: string;
  product_category_id: number;
  product_category_name: string;
  product_category_url: null;
  product_description: string;
  product_id: number;
  product_name: string;
  product_name_group: string;
  product_name_group_id: number;
  stock_id: number;
  stock_remaining: number | string;
  stock_value: string;
}

const CustomTillProductCard = ({ data }: any) => {
  let val: IProps = data;

  const { addToCart } = useCart();

  return (
    <>
      <Card style={{ backgroundColor: '#ffffff', height: 70, marginTop: 5, marginBottom: 5 }} mode="elevated">
        <Card.Content>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Image style={{ width: 40, height: 40, borderRadius: 10 }} source={require('../assets/itembox.png')} />
              <View style={{ marginLeft: 20 }}>
                <Text
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  style={{ fontWeight: '700', fontSize: 14, color: appColors.primary }}
                >
                  {val?.product_name}
                </Text>
                <Text style={{ fontWeight: '500', fontSize: 11, color: appColors.primary }}>
                  {val.stock_remaining} {val?.stock_value} Available
                </Text>
              </View>
            </View>
            <View style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
              {val?.stock_remaining == 'Out of Stock' || val?.stock_remaining == 0 ? (
                <div>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, color: 'red'}}>Out of Stock</Text>
                </div>
              ) : (
                <CustomButton
                  fontSize={12}
                  onPress={() => addToCart(data)}
                  bntType="primary"
                  mode="outlined"
                  text="Add to Cart "
                  disable={val?.stock_remaining == 'Out of Stock' ? true : false}
                />
              )}
            </View>
          </View>
        </Card.Content>
      </Card>
    </>
  );
};

export default CustomTillProductCard;

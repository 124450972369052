import { View, Text, Image, TouchableOpacity } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Appbar, Menu, Divider, Button } from 'react-native-paper';
import { appColors } from '../global/constant/colors';
import { useNavigation } from '@react-navigation/native';
import useAuth from '../hooks/useAuth';
import useUsers from '../hooks/useUsers';
import CustomButton from './CustomButton';

const TillHeader = () => {
  const { getUserFromAsync } = useUsers();
  const { logOut } = useAuth();
  const navigation: any = useNavigation();

  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    getUserFromAsync().then((data: any) => {
      setUser(data);
    });
  }, []);

  return (
    <Appbar.Header
      style={{
        backgroundColor: appColors.primary,
        paddingLeft: 10,
        paddingRight: 10,
        height: 80,
      }}
    >
      <View style={{ marginLeft: 20 }}>
        <CustomButton bntType="secondary" mode="outlined" text="Logout" onPress={logOut} />
      </View>
      {user?.role === 'Organisation Owner' || user?.role === 'Cashier Admin' ? (
        <View style={{ marginLeft: 20 }}>
          <CustomButton
            bntType="secondary"
            mode="outlined"
            text="Back To Admin"
            onPress={() => navigation.replace('app', { screen: 'lobbyScreen' })}
          />
        </View>
      ) : null}
      <View style={{ marginLeft: 20 }}>
        <Text style={{ color: '#ffffff', fontSize: 13, fontWeight: '800' }}>{`${user.first_name} (${user.role})`}</Text>
      </View>
    </Appbar.Header>
  );
};

export default TillHeader;

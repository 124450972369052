import { ReactNode } from 'react';
import { View } from 'react-native';
import { appColors } from '../../global/constant/colors';
import AdminHeader from '../../components/AdminHeader';

interface IProps {
  content: ReactNode;
}

const AdminLayout = ({ content }: IProps) => {
  return (
    <div
      style={{
        backgroundColor: appColors.secondary,
        width: '100vw',
      }}
    >
      <AdminHeader />
      <div style={{ marginLeft: '50px', marginRight: '50px', marginTop: '50px', minHeight: '100vh' }}>
        <div style={{overflowX: "auto", height: "85vh"}}>{content}</div>
      </div>
    </div>
  );
};

export default AdminLayout;

import { View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { Card } from 'react-native-paper';
import { Svg, Path } from 'react-native-svg';
import { appColors } from '../global/constant/colors';
import { useState, useEffect, useRef } from 'react';
import useCart from '../hooks/useCart';
import { setAsyncStorage, getAsyncStorage } from '../global/utils/asyncFun';
import useOrg from '../hooks/useOrg';

interface IProps {
  barcode: string;
  is_single: number;
  price: string;
  product_category_description: string;
  product_category_id: number;
  product_category_name: string;
  product_category_url: null;
  product_description: string;
  product_id: number;
  product_name: string;
  product_name_group: string;
  product_name_group_id: number;
  stock_id: number;
  stock_remaining: number;
  quantity: any;
  is_sold: number;
  name: string;
  product_status: string;
  stock_value: any;
  quantity_refunded: number;
  sub_total: string;
}

const CustomCartProductCard = ({ data, onShowQtyOutModalState }: any) => {
  let val: IProps = data;
  const { removeFromCartItem, setCartItemQty, setRefundData, undoRefundData, refundCheckOutData } = useCart();
  const { getOrgData, org } = useOrg();

  useEffect(() => {
    getOrgData();
  }, []);


  const onSetQtyVal = async () => {
    if (val?.stock_value != 1 && Number(val?.sub_total) != 0) {
      setAsyncStorage('_cartItemID', val?.product_id);
      onShowQtyOutModalState(val);
    }
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: '#ffffff',
          height: 75,
          marginTop: 5,
          marginBottom: 5,
          borderColor: val?.quantity == 0 ? 'red' : '#ffffff',
          borderWidth: 3,
        }}
        mode="elevated"
      >
        <Card.Content
          style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}
        >
          {/* <View style={{ width: 50, height: 50, backgroundColor: 'rgba(217, 211, 222, 1)', borderRadius: 10 }}></View> */}
          <Image style={{ width: 40, height: 40, borderRadius: 10 }} source={require('../assets/itembox.png')} />

          <Text numberOfLines={2} ellipsizeMode="tail" style={{ fontWeight: '700', fontSize: 14, color: appColors.primary, width: 150 }}>
            {val?.is_sold !== undefined ? val?.name : val?.product_name}
          </Text>
          <TouchableOpacity
            style={{
              borderWidth: 3,
              borderColor: 'rgba(30, 30, 30, 0.3)',
              backgroundColor:
                val.product_status != null
                  ? (val?.stock_value == 0 ? '#ffffff' : 'rgba(30, 30, 30, 0.1)') ||
                    (val?.stock_value == 1 ? 'rgba(30, 30, 30, 0.1)' : '#ffffff')
                  : '#ffffff',
              borderRadius: 5,
              padding: 7,
              width: 100,
            }}
            onPress={() => onSetQtyVal()}
          >
            <Text
              style={{ fontWeight: '700', fontSize: 15, marginTop: 2, color: appColors.primary, textAlign: 'center' }}
            >
              {val?.quantity} {val.stock_value == 'kilogram' ? 'kg' : 'pcs'}
            </Text>
          </TouchableOpacity>

          <View>
            <Text style={{ fontWeight: '700', fontSize: 13, color: appColors.primary }}>Price</Text>
            <Text style={{ fontWeight: '700', fontSize: 15, marginTop: 2, color: appColors.primary }}>
              {org?.country?.currency_symbol}
              {(parseFloat(val?.price) * Number(val?.quantity))?.toFixed(2)}
            </Text>
          </View>

          {val?.is_sold !== undefined ? (
            <>
              {console.log(refundCheckOutData?.products)}
              {refundCheckOutData?.products?.find((data: any) => data.product_id == val?.product_id) ? (
                <TouchableOpacity
                  onPress={() => undoRefundData(val?.product_id)}
                  style={{
                    backgroundColor: 'green',
                    padding: 15,
                    borderRadius: 10,
                    width: '12%',
                    marginRight: 10,
                  }}
                >
                  <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700', fontSize: 12 }}>Undo</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => setRefundData(val)}
                  style={{
                    backgroundColor: 'rgba(183, 35, 0, 1)',
                    padding: 15,
                    borderRadius: 10,
                    width: '12%',
                    marginRight: 10,
                  }}
                >
                  <Text style={{ color: '#ffffff', textAlign: 'center', fontWeight: '700', fontSize: 12 }}>Refund</Text>
                </TouchableOpacity>
              )}
            </>
          ) : (
            <TouchableOpacity onPress={() => removeFromCartItem(val?.product_id)}>
              <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <Path
                  d="M31.6666 10.6835L29.3166 8.3335L19.9999 17.6502L10.6833 8.3335L8.33325 10.6835L17.6499 20.0002L8.33325 29.3168L10.6833 31.6668L19.9999 22.3502L29.3166 31.6668L31.6666 29.3168L22.3499 20.0002L31.6666 10.6835Z"
                  fill="#1E1E1E"
                />
              </Svg>
            </TouchableOpacity>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

export default CustomCartProductCard;

import {
  GET_PRODUCT_GROUP,
  CREATE_PRODUCT_GROUP,
  CREATE_PRODUCT_ITEM,
  GET_PRODUCT_ITEM,
  GET_INVENTORY,
  PRODUCT_UPDATE,
  GET_STOCK_RECORD,
  ADD_BARCODE_TO_PRODUCT,
  EDIT_PRODUCT_GROUP_NAME,
  DELETE_PRODUCT_GROUP_NAME,
  ARCHIVE_PRODUCT_GROUP_NAME,
  DELETE_PRODUCT,
  GET_ALL_PRODUCT_GROUP_FOR_LOCAL_DB,
  GET_ALL_SHOP_PRODUCT,
  EDIT_PRODUCT,
} from '../global/constant/apiRoutes';
import useAxios from './useAxios';
import useDialogState from './useDialogState';
import { useSelector, useDispatch } from 'react-redux';
import {
  setProductNameGroupData,
  setProductItemData,
  setAllShopProductData,
  setInventoryData,
  setStockRecordData,
} from '../global/appState/slice/customProductSlice';
import { getAsyncStorage } from '../global/utils/asyncFun';
import { RootState } from '../global/appState/store';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';

const useProduct = () => {
  const navigation: any = useNavigation();
  const dispatch = useDispatch();
  const { apiCall } = useAxios();
  const { setDialogShowState, onSetPreloadState } = useDialogState();
  const { productNameGroupData, productNameItemData, allShopProductData, inventoryData, stockRecordData }: any =
    useSelector((state: RootState) => state.customProduct);

  const getAllProductNameGroup = async (product_category_id: number, search = '') => {
    onSetPreloadState(true);
    try {
      const res = await apiCall(
        'GET',
        search?.length >= 1
          ? `${GET_PRODUCT_GROUP}?product_category_id=${product_category_id}&search=${search}`
          : `${GET_PRODUCT_GROUP}?product_category_id=${product_category_id}`,
      );
      console.log('product name', res.data);
      dispatch(setProductNameGroupData(res.data.name_groups));
      onSetPreloadState(false);
    } catch (error: any) {
      onSetPreloadState(false);
      console.log('get Product error =>', error.response);
    }
  };

  const getProductItem = async (product_group_id: number) => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_PRODUCT_ITEM}?shop_id=${shopId.id}&product_group_id=${product_group_id}`);
      console.log('product item', res?.data?.data?.shop_products);
      dispatch(setProductItemData(res?.data?.data?.shop_products));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get Product error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getAllShopProduct = async () => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_PRODUCT_ITEM}?shop_id=${shopId.id}`);
      console.log('all shop product==>', res?.data?.data?.shop_products);
      dispatch(setAllShopProductData(res?.data?.data?.shop_products));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get Product error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const createProductNameGroup = async (
    data: {
      product_name_group: string;
      price: string;
      product_category_id: number;
    },
    nextFun?: any,
  ) => {
    onSetPreloadState(true);
    if (data.product_name_group == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Product name is needed', 'Try Again');
      return;
    }
    if (data.price == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Product price  is needed', 'Try Again');
      return;
    }
    const regex = /^[0-9]+(\.[0-9]+)?$/;

    if (regex.test(data.price) == false) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Price can only be numbers not letters', 'Try Again');
      return;
    }

    if (
      productNameGroupData.some(
        (obj: any) => obj.product_name_group?.toLowerCase() == data.product_name_group.toLowerCase(),
      )
    ) {
      onSetPreloadState(false);
      setDialogShowState(
        true,
        'Create product name error',
        'nice try, but this product name group already exist',
        'Try Again',
      );

      return;
    }

    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('POST', CREATE_PRODUCT_GROUP, {
        product_name_group: data.product_name_group,
        price: Number(data.price),
        product_category_id: data.product_category_id,
        shop_id: shopId.id,
      });
      setDialogShowState(true, 'Create Product Name Success', res.data.message, 'Continue', 'success');
      getAllProductNameGroup(data.product_category_id);
      onSetPreloadState(false);
      nextFun();
    } catch (error: any) {
      console.log('Create Product name group error =>', error.response);
      setDialogShowState(true, 'Edit Product Name Success', error.response.data.message, 'Continue', 'success');
      onSetPreloadState(false);
      nextFun();
    }
  };

  const editProductNameGroup = async (
    data: {
      product_name_group: string;
      product_name_group_id: number;
      product_category_id: number;
      price: string;
    },
    nextFun?: any,
  ) => {
    onSetPreloadState(true);
    nextFun();
    if (data.product_name_group == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Product name is needed', 'Try Again');
      return;
    }
    if (data.price == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Product price  is needed', 'Try Again');
      return;
    }
    const regex = /^[0-9]+(\.[0-9]+)?$/;

    if (regex.test(data.price) == false) {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product name error', 'Price can only be numbers not letters', 'Try Again');
      return;
    }
    // if (
    //   productNameGroupData.some(
    //     (obj: any) => obj.product_name_group?.toLowerCase() == data.product_name_group.toLowerCase(),
    //   )
    // ) {
    //   onSetPreloadState(false);
    //   nextFun();
    //   setDialogShowState(
    //     true,
    //     'Create product name error',
    //     'nice try, but this product name group already exist',
    //     'Try Again',
    //   );

    //   return;
    // }

    try {
      const res = await apiCall('POST', EDIT_PRODUCT_GROUP_NAME, {
        product_name_group: data.product_name_group,
        product_name_group_id: data.product_name_group_id,
        price: data.price,
      });
      setDialogShowState(true, 'Edit Product Name Success', res.data.message, 'Continue', 'success');
      getAllProductNameGroup(data.product_category_id);
      onSetPreloadState(false);
      nextFun();
    } catch (error: any) {
      console.log('Create Product name group error =>', error.response);
      setDialogShowState(true, 'Edit Product Name Error', error.response.data.message, 'Continue', 'error');
      onSetPreloadState(false);
      nextFun();
    }
  };

  const createProductItemManually = async (
    data: {
      name: string;
      description: number | any;
      product_name_group_id: number;
      is_single: number;
      category_id: number;
      stock: string;
      stock_value: string;
      cost_per_unit: string;
    },
    nextFun?: any,
  ) => {
    onSetPreloadState(true);
    if (data.name == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product item error', 'Product item name is needed', 'Try Again');
      return;
    }
    if (data.stock == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product item error', 'Product item stock is needed', 'Try Again');
      return;
    }
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('POST', CREATE_PRODUCT_ITEM, {
        name: data.name,
        product_name_group_id: data.product_name_group_id,
        shop_id: shopId.id,
        is_single: data.is_single,
        description: data.description,
        category_id: data.category_id,
        stock: Number(data.stock),
        stock_value: Number(data.stock_value),
        cost_per_unit: Number(data.cost_per_unit),
      });
      setDialogShowState(true, 'Create Product Item Success', res.data.message, 'Continue', 'success');
      nextFun();
      onSetPreloadState(false);
      getProductItem(data?.product_name_group_id);
      navigation.replace('app', { screen: 'categoryScreen' });
    } catch (error: any) {
      setDialogShowState(true, 'Create product item error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const createProductItemByScanner = async (data: any, nextFun?: any) => {
    onSetPreloadState(true);
    if (data?.name == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product item error', 'Product item name is needed', 'Try Again');
      return;
    }
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('POST', CREATE_PRODUCT_ITEM, { ...data, shop_id: shopId.id, stock_value: 1 });
      setDialogShowState(true, 'Create Product Item Success', res.data.message, 'Continue', 'success');
      nextFun();
      getProductItem(data?.product_name_group_id);
      navigation.replace('app', { screen: 'categoryScreen' });
      onSetPreloadState(false);
    } catch (error: any) {
      setDialogShowState(true, 'Create product item error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const updateProductStock = async (
    data: any,
    product_name_group_id: number,
    type: string,
    nextFun?: any,
    onClear?: any,
  ) => {
    onSetPreloadState(true);
    nextFun();
    if (data?.stock == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product item error', 'Product stock can not be empty', 'Try Again');
      return;
    }
    try {
      const res = await apiCall('POST', PRODUCT_UPDATE, { ...data, type: type });
      setDialogShowState(true, 'Product stock update Success', res.data.message, 'Continue', 'success');
      getProductItem(product_name_group_id);
      onClear();
      onSetPreloadState(false);
    } catch (error: any) {
      setDialogShowState(true, 'Edit product stock error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Edit Product  stock item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const updateProduct = async (
    data: any,
    product_name_group_id: number,
    nextFun?: any,
    onClear?: any,
  ) => {
    onSetPreloadState(true);
    nextFun();
    if (data?.stock == '') {
      onSetPreloadState(false);
      setDialogShowState(true, 'Create product item error', 'Product stock can not be empty', 'Try Again');
      return;
    }
    try {
      const res = await apiCall('POST', EDIT_PRODUCT, data);
      setDialogShowState(true, 'Product update Success', res.data.message, 'Continue', 'success');
      getProductItem(product_name_group_id);
      onClear();
      onSetPreloadState(false);
    } catch (error: any) {
      setDialogShowState(true, 'Edit product item error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Edi Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const updateProductBarcode = async (data: any, product_name_group_id: number, nextFun?: any, onClear?: any) => {
    onSetPreloadState(true);
    nextFun();

    try {
      const res = await apiCall('POST', ADD_BARCODE_TO_PRODUCT, data);
      setDialogShowState(true, 'Product stock update Success', res.data.message, 'Continue', 'success');
      getProductItem(product_name_group_id);
      onClear();
      onSetPreloadState(false);
    } catch (error: any) {
      setDialogShowState(true, 'Create product item error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getInventory = async (categoryId: number, search?: string) => {
    let shopId = await getAsyncStorage('shop');
    onSetPreloadState(true);
    try {
      const res = await apiCall(
        'GET',
        `${GET_INVENTORY}?shop_id=${shopId.id}&category_id=${categoryId}&search=${search}`,
      );
      console.log('product name', res.data.data);
      dispatch(setInventoryData(res.data.data));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get inventory error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getStockRecord = async (stock_id: any) => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_STOCK_RECORD}?stock_id=${stock_id}`);
      console.log('stock', res?.data?.data);
      dispatch(setStockRecordData(res?.data?.data));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get Product error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getStockRecordByDate = async (stock_id: any, _data: { date_from: string; date_to: string }) => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall(
        'GET',
        `${GET_STOCK_RECORD}?stock_id=${stock_id}&date_from=${moment(_data.date_from).format('YYYY-MM-DD')}&date_to=${moment(_data.date_to).format('YYYY-MM-DD')}`,
      );
      console.log('stock', res?.data?.data);
      dispatch(setStockRecordData(res?.data?.data));
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get Product error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const printLabel = (
    productName: any,
    productPrice: any,
    barcodeValue: any,
    currency_symbol: any,
    shopName: any,
    shopAddress: any,
  ) => {
    function createPrintContent() {
      return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>Receipt</title>
            <style>
               @page {
                margin: 0;
               }
                body {
                    font-family: Arial, sans-serif;
                }
                .card {
                  background-color: #ffffff;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
                    display: flex;
                    text-align: center;
                    align-items: center;     
                    justify-content: center;
                    width: 480px;
                    height: 288px;
                }
                .product-name {
                    font-weight: 600;
                    font-size: 15px;
                    color: #000;
                   
                }
                .company-name {
                    font-weight: bold;
                    font-size: 20px;
                    margin-top: 10px;
                    color: #000;
                    line-height: 2px;
                    text-align: center;
                    text-transform: uppercase;
                }
                .company-address {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 2px;
                    color: #000;
                    text-align: center;
                }
                .product-price {
                    font-weight: 600;
                    font-size: 17px;
                    margin: 2px 0;
                }
                .barcode {
                    height: 80px;
                    margin-top: 5px;
                }
            </style>
            <script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.5/dist/JsBarcode.all.min.js"></script>
        </head>
        <body>
            <div class="card">
                <div class="card-content">
                <div style="border-top: 5px solid #000"></div>
                 <h1 class="company-name">${shopName}</h1>
                 <p class="company-address">${shopAddress}</p>
                  <div style="border: 2px solid #000">
                   <p class="product-name">${productName}</p>
                  </div>
                    <svg class="barcode"></svg>    
                    <p class="product-price">${currency_symbol} ${productPrice}</p>
                    <div style="border-top: 5px solid #000"></div>
                </div>
            </div>
            <script>
                window.onload = function() {
                    JsBarcode(".barcode", "${barcodeValue}", {
                        height: 50,
                        width: 3
                    });
                    window.print();
                };
            </script>
        </body>
        </html>
      `;
    }

    const printWindow = window.open('', '', 'height=600,width=400');
    printWindow.document.write(createPrintContent());
    printWindow.document.close();
  };

  const syncProductNameGroupOnLocalDB = async () => {
    onSetPreloadState(true);
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_ALL_PRODUCT_GROUP_FOR_LOCAL_DB}?shop_id=${shopId.id}`);
      console.log('product name group local DB=> ', res.data.product_group_names);
      const data = res.data.product_group_names.map((val: any) => ({
        id: val.id,
        product_name_group: val.product_name_group,
        product_category_id: val.product_category_id,
        price: val.price,
        is_archived: val.is_archived,
      }));

      const result = await window.electron.ipcRenderer.invoke('insert-product-group-name', data);
      onSetPreloadState(false);
    } catch (error: any) {
      onSetPreloadState(false);
      console.log('get Product error local DB=>', error.response);
    }
  };

  const getAllProductNameGroupOnLocalDB = async (categoryId: number) => {
    onSetPreloadState(true);
    try {
      window?.electron?.ipcRenderer
        .invoke('fetch-product-group-name', categoryId)
        .then((res: any) => {
          console.log('fetch-product-group-name on local DB', res);
          dispatch(setProductNameGroupData(res));
        })
        .catch((error: any) => {
          console.error('Failed to fetch product group name:', error);
        });
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('fetch-product-group-name error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const searchProductGroupNameWithKeyWordOnLocalDB = async (search = '') => {
    onSetPreloadState(true);
    try {
      const result = await window.electron.ipcRenderer.invoke('search-productGroupName', search);

      if (result.success) {
        console.log('Search ProductGroupName local BD  results:', result.result);
        dispatch(setProductNameGroupData(result.result));
      } else {
        console.error('Search failed:', result.error);
      }
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get category error  local BD  =>', error);
      onSetPreloadState(false);
    }
  };

  const getAllProductOnLocalDBByGroupNameId = async (productGroupNameId: number) => {
    onSetPreloadState(true);
    try {
      window?.electron?.ipcRenderer
        .invoke('fetch-product-by-group-name-id', productGroupNameId)
        .then((res: any) => {
          console.log('fetch-product-by-group-name-id on local DB', res);
          dispatch(setProductItemData(res));
        })
        .catch((error: any) => {
          console.error('Failed to fetch-product-by-group-name-id:', error);
        });
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('fetch-product-by-group-name-id error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const getAllProductOnLocalDB = async () => {
    onSetPreloadState(true);
    try {
      window?.electron?.ipcRenderer
        .invoke('fetch-product-all')
        .then((res: any) => {
          console.log('fetch-product-all on local DB', res);
          dispatch(setAllShopProductData(res));
        })
        .catch((error: any) => {
          console.error('Failed to fetch-product-all:', error);
        });
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('fetch-product-all error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const syncAllShopProductOnLocalBD = async () => {
    onSetPreloadState(true);
    try {
      await window.electron.ipcRenderer.invoke('clear-product-table');
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('GET', `${GET_PRODUCT_ITEM}?shop_id=${shopId.id}`);
      console.log('all shop product local db==>', res?.data?.data?.shop_products);
      const productData = [].concat.apply([], Object.values(res?.data?.data?.shop_products));
      console.log(' productData =>>', productData);
      const data = productData?.map((val: any) => ({
        product_id: val.product_id,
        product_name_group_id: val.product_name_group_id,
        is_single: val.is_single,
        is_sold: val.is_sold,
        category_id: val.product_category_id,
        price: val.price,
        product_name: val.product_name,
        barcode: val.barcode,
        description: val.product_description == null ? '' : val.product_description,
        stock_id: val.stock_id,
        stock_remaining: val.stock_remaining,
        stock_value: val.stock_value,
      }));
      console.log(' data =>>', data);
      const resD = await window.electron.ipcRenderer.invoke('insert-product', data);
      console.log('syncAllShopProductOnLocalBD resD =>>', resD);
      dispatch(setAllShopProductData(resD));
      getAllProductOnLocalDB();
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('get Product error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const updateStockRemainingOnLocalDB = async (data: any) => {
    try {
      window?.electron?.ipcRenderer.invoke('update_stock_remaining', data);
      onSetPreloadState(false);
    } catch (error: any) {
      console.log('update_stock_remaining error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const deleteProductGroupName = async (product_name_group_id: number, product_category_id: number, nextFun?: any) => {
    onSetPreloadState(true);
    nextFun();
    try {
      const res = await apiCall('POST', DELETE_PRODUCT_GROUP_NAME, { product_name_group_id: product_name_group_id });
      setDialogShowState(true, 'Delete product group Success', res.data.message, 'Continue', 'success');
      getAllProductNameGroup(product_category_id);
      onSetPreloadState(false);
      syncAllShopProductOnLocalBD();
      navigation.replace('app', { screen: 'categoryScreen' });
    } catch (error: any) {
      setDialogShowState(true, 'Delete product group error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const deleteProduct = async (product_id: number, product_category_id: number, nextFun?: any) => {
    console.log('=>?', product_id, product_category_id);
    onSetPreloadState(true);
    nextFun();
    try {
      let shopId = await getAsyncStorage('shop');
      const res = await apiCall('DELETE', DELETE_PRODUCT, { product_id: product_id, shop_id: shopId?.id });
      setDialogShowState(true, 'Delete product Success', res.data.message, 'Continue', 'success');
      getAllProductNameGroup(product_category_id);
      onSetPreloadState(false);
      syncAllShopProductOnLocalBD();
      navigation.replace('app', { screen: 'categoryScreen' });
    } catch (error: any) {
      setDialogShowState(true, 'Delete product  error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  const archiveProductGroupName = async (product_name_group_id: number, product_category_id: number, nextFun?: any) => {
    onSetPreloadState(true);
    nextFun();
    try {
      const res = await apiCall('POST', ARCHIVE_PRODUCT_GROUP_NAME, { product_name_group_id: product_name_group_id });
      setDialogShowState(true, 'Archive product group Success', res.data.message, 'Continue', 'success');
      getAllProductNameGroup(product_category_id);
      onSetPreloadState(false);
      syncAllShopProductOnLocalBD();
      navigation.replace('app', { screen: 'categoryScreen' });
    } catch (error: any) {
      setDialogShowState(true, 'Archive product group error', `${error?.response.data?.message}`, 'Try Again', 'error');
      console.log('Create Product item error =>', error.response);
      onSetPreloadState(false);
    }
  };

  return {
    createProductNameGroup,
    getAllProductNameGroup,
    createProductItemManually,
    getProductItem,
    createProductItemByScanner,
    getAllShopProduct,
    getInventory,
    updateProductStock,
    getStockRecord,
    getStockRecordByDate,
    printLabel,
    updateProductBarcode,
    editProductNameGroup,
    deleteProductGroupName,
    archiveProductGroupName,
    deleteProduct,
    getAllProductNameGroupOnLocalDB,
    getAllProductOnLocalDBByGroupNameId,
    syncProductNameGroupOnLocalDB,
    searchProductGroupNameWithKeyWordOnLocalDB,
    syncAllShopProductOnLocalBD,
    getAllProductOnLocalDB,
    updateStockRemainingOnLocalDB,
    updateProduct,
    productNameGroupData,
    productNameItemData,
    allShopProductData,
    inventoryData,
    stockRecordData,
  };
};

export default useProduct;

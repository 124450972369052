import { ReactNode } from 'react';
import { View, Dimensions, ScrollView } from 'react-native';
import { appColors } from '../../global/constant/colors';
import { Appbar } from 'react-native-paper';
import TillHeader from '../TillHeader';

interface IProps {
  leftSideContent?: ReactNode;
  headerContent?: ReactNode;
  mainContent?: ReactNode;
  footerContent?: ReactNode;
}

const Layout = ({ headerContent, mainContent, footerContent, leftSideContent }: IProps) => {
  const windowHeight = Dimensions.get('screen').height;
  return (
    <div style={{height: windowHeight, margin: 0, padding: 0, display: "flex",}}>
      <div
        style={{
          backgroundColor: appColors.secondary,
          height: windowHeight,
          width: '50%',
        }}
      >
        <TillHeader />
        {leftSideContent}
      </div>
      <View style={{ backgroundColor: '#efeded', height: windowHeight, width: '50%' }}>
        <Appbar.Header
          elevated={true}
          style={{
            backgroundColor: appColors.primary,
            paddingLeft: 20,
            paddingRight: 20,
            height: 80,
          }}
        >
          {headerContent}
        </Appbar.Header>

        <View style={{ width: '100%', paddingVertical: 20, paddingHorizontal: 20, height: '70%', paddingBottom: 100 }}>
          <ScrollView showsVerticalScrollIndicator={false} scrollEnabled={true}>
            {mainContent}
          </ScrollView>
        </View>

        <View
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingHorizontal: 20,
            paddingBottom: 150,
            backgroundColor: '#efeded',
          }}
        >
          {footerContent}
        </View>
      </View>
    </div>
  );
};

export default Layout;
